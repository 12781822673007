<template>
    <el-dialog 
        title="新增活动" 
        width="550px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="cancelHandle"
        :visible.sync="dialogFormVisible">
            
        <el-form 
            ref="activityForm"
            :model="form" 
            :rules="rules"
            label-width="90px" 
            >
            <el-form-item label="活动标题" prop="title">
                <el-input 
                    v-model="form.title" 
                    placeholder="输入活动标题" 
                    autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="活动图片" prop="bannerImageUrl">
                <el-input 
                    v-model="form.bannerImageUrl" 
                    placeholder="输入图片链接" 
                    autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="需连接人数" prop="recommendRegisterCount">
                <el-input 
                    placeholder="输入需连接人数" 
                    v-model.trim="form.recommendRegisterCount" 
                    onkeyup="value=value.replace(/[^\d]/g, '')"
                    autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="活动时间" prop="dateRange">
                <el-date-picker
                    style="width: 100%;"
                    v-model="form.dateRange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="handleDate"
                    :picker-options="pickerOptions"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="活动描述" prop="descrption">
                <el-input 
                    v-model="form.descrption"
                    type="textarea"
                    placeholder="输入活动描述"
                    :autosize="{ maxRows: 4, minRows: 4}"
                    resize="none"
                    maxlength="2000"
                    show-word-limit></el-input>
            </el-form-item>
            
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button 
                type="primary" 
                @click="confirmHandle" 
                :loading="isConfirming"
                :disabled="isConfirming"
                >确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import moment from 'moment';
import { jdt as jdtUrl } from '#/js/config/javaApi.json';
export default {
    name: 'add-activity',
	components: {
	},
	data() {
        let _minTime = null,
            _maxTime = null;
		return {
            form: {
                title: '',  // 20个字
                bannerImageUrl: '',
                recommendRegisterCount: 0,
                dateRange: null,  // 一年
                descrption: '',
                startDate: '',
                endDate: '',
            },
            // defaultDateRange: [moment().format("YYYY-MM-DD"), moment().add(7, 'days').format("YYYY-MM-DD")],
            rules: {
                title: [
                    { required: true, message: '请填写活动标题', trigger: 'blur' },
                    { max: 20, message: '活动名称长度应在20个字符以内', trigger: 'blur' }
                ],
                bannerImageUrl: [
                    { required: true, message: '请填写活动图片', trigger: 'blur' },
                    { max: 255, message: '活动图片应在255个字符以内', trigger: 'blur' }
                ],
                recommendRegisterCount: [
                    {
                        validator: (rules, value, callback) => {
                            if(+value >=10000 || +value < 0) {
                                callback(`连接人数必须大于等于0且小于10000`);
                            } else {
                                callback();
                            }
                        }, trigger: 'blur'
                    }
                ],
                dateRange: [
                    {
                        required: true, message: '请选择活动时间', trigger: 'blur'
                    },
                    // {
                    //     validator: (rules, value, callback) => {
                    //         // this.validateJobShareItem('customerName', value, callback)
                    //     }, trigger: 'blur'
                    // }
                ],
                descrption: [{
                    required: true, message: '请填写活动描述', trigger: 'blur'
                }],
            },
            dialogFormVisible: false,
            
            pickerOptions: {
                onPick: time => {
                    if (!time.maxDate) {
                        let timeRange = 365*24*60*60*1000, // *天
                            _dNow = new Date().getTime();

                        _minTime = time.minDate.getTime() - timeRange; // 最小时间
                        _maxTime = time.minDate.getTime() + timeRange; // 最大时间
                        // _maxTime = moment(_maxTime).isSameOrAfter(_dNow) ? _dNow: _maxTime;
                    } else {
                        _minTime = _maxTime = null;
                    }
                },
                disabledDate: time => {
                    // onPick后触发
                    if(_minTime && _maxTime){
                        return time.getTime() < _minTime || time.getTime() > _maxTime;
                    }
                    if(!_minTime && _maxTime) {
                        return time.getTime() > _maxTime;
                    }
                },
            },

            isConfirming: false,
        };
    },
    created() {

    },
	mounted() {
		
	},
	methods: {
        show() {
            this.dialogFormVisible = true;
            this.$set(this.form, 'dateRange', null);
            this.$refs.activityForm && this.$refs.activityForm.resetFields();
        },
        
        cancelHandle() {
            this.dialogFormVisible = false;
        },
        
        confirmHandle() {
            this.$refs.activityForm.validate(isValid => {
                console.log(isValid)
                if(isValid) {
                    this.addActivity();
                }
            });
        },
        addActivity() {
            let params = {
                ...this.form
            };
            delete params.dateRange;
            
            this.isConfirming = true;
            _request({
                method: 'POST',
                url: jdtUrl.jdt_activity_add,
                baseURL: 'LbdJavaApi',
                javaProject: 'jdt',
                data: params,
            }).then(res =>{
                this.dialogFormVisible = false;
                shortTips(`活动添加成功！`);
                this.$emit('add-activity-cb');
            }).finally(() => {
                this.isConfirming = false;
            });
        },
        handleDate(value) {
            this.form.startDate = moment(value[0]).format('YYYY-MM-DD');
            this.form.endDate = moment(value[1]).format('YYYY-MM-DD');
        },
    }
}
</script>
<style lang="scss" scope>
.el-dialog__wrapper .el-dialog__body {
    padding: 15px 40px 20px;
}
</style>

