var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        width: _vm.width,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false,
        "custom-class": "image-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "post-container",
          style: { width: _vm.width, height: _vm.height },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.viewLoading,
                  expression: "viewLoading",
                },
              ],
              staticClass: "content",
            },
            [
              _c("div", { staticClass: "content-inner" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.content.bannerImageUrl,
                      onerror: _vm.defaultImg,
                      alt: _vm.content.title,
                    },
                  }),
                ]),
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.content.title) +
                      "\n                "
                  ),
                ]),
                _c("div", { staticClass: "time" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.content.startDate) +
                      "/" +
                      _vm._s(_vm.content.endDate) +
                      "\n                "
                  ),
                ]),
                _c("pre", {
                  domProps: { textContent: _vm._s(_vm.content.descrption) },
                }),
              ]),
            ]
          ),
          _c("span", {
            staticClass: "close-post-btn",
            on: { click: _vm.hideDialog },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }