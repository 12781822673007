var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "新增活动",
        width: "550px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancelHandle,
        visible: _vm.dialogFormVisible,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "activityForm",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "90px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入活动标题", autocomplete: "off" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动图片", prop: "bannerImageUrl" } },
            [
              _c("el-input", {
                attrs: { placeholder: "输入图片链接", autocomplete: "off" },
                model: {
                  value: _vm.form.bannerImageUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "bannerImageUrl", $$v)
                  },
                  expression: "form.bannerImageUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "需连接人数", prop: "recommendRegisterCount" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "输入需连接人数",
                  onkeyup: "value=value.replace(/[^\\d]/g, '')",
                  autocomplete: "off",
                },
                model: {
                  value: _vm.form.recommendRegisterCount,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form,
                      "recommendRegisterCount",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "form.recommendRegisterCount",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动时间", prop: "dateRange" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "daterange",
                  align: "right",
                  "unlink-panels": "",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "picker-options": _vm.pickerOptions,
                },
                on: { change: _vm.handleDate },
                model: {
                  value: _vm.form.dateRange,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "dateRange", $$v)
                  },
                  expression: "form.dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动描述", prop: "descrption" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "输入活动描述",
                  autosize: { maxRows: 4, minRows: 4 },
                  resize: "none",
                  maxlength: "2000",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.form.descrption,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "descrption", $$v)
                  },
                  expression: "form.descrption",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.isConfirming,
                disabled: _vm.isConfirming,
              },
              on: { click: _vm.confirmHandle },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }