<template>
  	<div class="ztc-container">
        <div class="ztc-top">
            <div class="banner">
                <img 
                    :src="bannerInfo.bannerImageUrl || defaultImg"
                    :onerror="defaultImg"
                    @click="viewActivity(bannerInfo.id)"
                    alt="职通车banner">
            </div>
            <div class="right-entrys">
                <div 
                    class="top-btn top-add-activity" 
                    @click="handleAddActivity"
                    v-if="userInfo.isAdministrator"
                    >新增活动</div>
                <div class="top-btn" @click="handleJdtReport">Csdn职通车数据</div>
                <div class="top-btn" @click="showJdtDialog">获取职通车二维码</div>
            </div>
        </div>
        <div class="ztc-bottom">
            <div class="ztc-l">
                <h2 class="ztc-l-list-title">Csdn职通车活动</h2>
                <div class="list-wrap" v-loading="activityListLoading">
                    <template v-if="activityList.length">
                    <div class="list-item"
                        v-for="(item, index) in activityList"
                        :key="'activityList_'+ index"
                        >
                        <div class="list-l ell" 
                            @click="viewActivity(item.id)" 
                            v-text="item.title">标题内容兴致冲冲的擦好发霉的镜头，相机充</div>
                        <div class="list-m">{{item.startDate}}/{{item.endDate}}</div>
                        <div class="list-r"
                            :class="{'hidden': !(userInfo.isAdministrator)}"
                            >
                            <!-- :class="{'hidden': !(userInfo.isAdministrator || job.isBinding)}" -->
                            <el-dropdown
                                placement="bottom"
                                @command="handleActivityOperate(item.id, ...arguments)">
                                <span
                                    class="el-dropdown-link el-icon-more"
                                    v-loading="moreOperateLoading"
                                ></span>
                                <el-dropdown-menu
                                    class="job-more-opr-dropdown"
                                    slot="dropdown">
                                    <el-dropdown-item 
                                        command="removeActivity" 
                                        >删除活动</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                    </template>
                </div>

                <div class="empty" v-if="activityList.length ==0">
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>

                <div class="pagination-wrap">
                <el-pagination
                    :current-page="pager.current"
                    :page-sizes="[10, 30, 50]"
                    :page-size="pager.take"
                    class="job-list-pagination"
                    layout="prev, pager, next, slot, total, sizes"
                    :total="pager.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                    <span class="pagination-text">
                        <span>
                            前往
                            <el-input
                                v-model="pagerJump"
                                @keyup.enter.native="handlePagerJump(pagerJump)"
                            ></el-input>
                            页
                        </span>
                        <span @click="handlePagerJump(pagerJump)">
                            跳转
                        </span>
                    </span>
                </el-pagination>
                </div>
            </div>
            <div class="ztc-r">
                <div
                    class="job-order-list-wrapper"
                    >
                    <div class="job-order-list-title">
                        <!-- <i class="job-order-list-icon el-icon-s-custom"></i> -->
                        连接候选人数排行榜
                    </div>
                    
                    <div class="job-order-list" v-loading="memberLoading">
                        <template v-if="memberOrders.length">
                            <div
                                class="job-order-item"
                                :key="memberIndex"
                                v-for="(member, memberIndex) in memberOrders">
                                <i class="job-order-item-icon el-icon-s-custom"></i>
                                <a
                                    class="job-order-item-name"
                                    href="javascript:;"
                                    >
                                    {{member.realName}}
                                </a>
                                <!-- :href="`/User/${member.headhuntingUnionId}`" -->
                                <!-- @click="addParams('creator', member.headhuntingUnionId)" -->
                                <span
                                    class="job-order-item-count"
                                    >
                                    {{member.total}}
                                </span>
                            </div>
                        </template>
                        <span class="job-order-list-null" v-else>暂无数据</span>
                    </div>
                    <!-- <div
                        class="job-order-more-btn"
                        @click="showMoreJobOrder('1')"
                        v-if="!memberLoading && memberOrders.length">
                        加载更多
                    </div> -->
                </div>

                <div class="ztc-news">
                    <h2 class="title">Csdn职通车最新动态</h2>
                    <div class="news-container" v-loading="tendLoading">
                        <ul>
                            <li class="news-item" 
                                v-for="(item, index) in trendList" 
                                :key="'trend_'+index">
                                <avatar
                                    class="pic"
                                    size="md"
                                    :src="formatAvatarUrl(`/Data/Avatar/${item.avatarId}`)"
                                    :userId="item.posterId"
                                    :enableCard="true"
                                    :enableLink="true"
                                ></avatar>
                                <div class="new-content">
                                    <p>
                                        <span class="name ell" :title="item.posterRealName + '@' + item.posterNickName">
                                            <span class="real-name">{{item.posterRealName}}</span>
                                            @{{item.posterNickName}}
                                        </span>
                                        <span class="time">{{item.created}}</span>
                                    </p>
                                    <p v-html="computedDescription(item)"></p>
                                </div>
                            </li>
                            <div class="empty" v-if="trendList.length==0">暂无数据</div>
                            <div class="news-bottom"
                                v-if="showMore"
                                @click="handleShowMore">
                                <span class="news-show-more">加载更多<i class="el-icon-d-arrow-right"></i></span>
                            </div>
                        </ul>
                    </div>

                </div>


            </div>
        </div>

        <add-activity
            ref="addActivity"
            @add-activity-cb="addActivityCb"
        />

        <image-dialog
            width="500px"
            height="880px"
            :imageSrc="jdtQcodeImage"
            imageAlt="Csdn职通车二维码"
            v-if="userInfo.isCFUser && jdtDialogVisible"
            @hideDialog="hideJdtDialog"
        ></image-dialog>

        <view-activity
            ref="viewActivity"
            width="880px"
            height="700px"
            @hideDialog="hideJdtDialog"
        ></view-activity>
    </div>
</template>

<script>
import Avatar from '#/component/common/avatar.vue';
import ImageDialog from '#/component/activity/imageDialog.vue'
import AddActivity from './component/add-activity.vue';
import ViewActivity from './component/view-activity.vue';
import { jdt as jdtUrl } from '#/js/config/javaApi.json';

export default {
	componentName: 'ztc',
	components: {
		Avatar,
        ImageDialog,
        AddActivity,
        ViewActivity,
	},
	data() {
		return {
            pager: {
                current: 1,
                take: 10,
                total: 0
            },
            pagerJump: 0,

            trendList: [],  // 动态
            trendTotal: 0,
            trendStart: 1,

            bannerInfo: {},
            // defaultImg: 'this.src="' + require('@src/assets/images/ztc/ztc-banner.jpg') + '"',
            defaultImg: 'this.src="' + require('@src/assets/images/ztc/banner.png') + '"',

            activityList: [],
            activityListLoading: false,

            memberLoading: false,
            memberOrders: [],

            tendLoading: false,

            jdtDialogVisible: false,

            moreOperateLoading: false,
		}
	},
    created() {
        this.getJdtList();
        this.getMembersList();
        this.getStreamData();
    },
	mounted() {
		if(!this.isAdministrator && !this.isBusinessPermission){
            shortTips("权限不足，请联系管理员")
            sessionStorage.setItem('tabItems', JSON.stringify([]));
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }
	},
    computed: {
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        jdtQcodeImage() {
            return `${_host.portal}/Sharing/${this.$store.state.user.userInfo.id}/JDTQRCode`
        },
        showMore() {
            return this.trendTotal > (this.trendStart-1) * 5;
        },
        isAdministrator() {
            return this.$store.state.user.userInfo.isAdministrator || false;
        },
        isBusinessPermission() {
            return (this.userInfo.privilegeCodeList || []).includes('Business');
        }
    },
	methods: {
        formatAvatarUrl(url) {
            return _host.portal + url;
        },
        // 获取职职通车数据
        getJdtList(needScroll=false) {
            const params = {
                currentPage: this.pager.current,
                pageSize: this.pager.take,
            };
            this.activityListLoading = true;
            
            _request({
                method: 'POST',
                url: jdtUrl.jdt_activity_list,
                baseURL: 'LbdJavaApi',
                javaProject: 'jdt',
                data: params,
            }).then(res =>{
                this.activityList = res.data || [];
                this.bannerInfo = res.extraData || {};
                this.pager.total = res.count;
                if(needScroll) {
                    // document.documentElement.scrollTop = 0;
                    document.querySelector('.ztc-container').scrollTop = 0;
                }
            }).finally(() => {
                this.activityListLoading = false;
            });
        },
        getMembersList() {
            this.memberLoading = true;
            _request({
                method: 'GET',
                url: jdtUrl.jdt_activity_rank_list,
                baseURL: 'LbdJavaApi',
                javaProject: 'jdt',
            }).then(res =>{
                this.memberOrders = res || [];
                if(this.memberOrders.length > 10) this.memberOrders.length = 10;
            }).finally(() => {
                this.memberLoading = false;
            });
        },
        handleSizeChange(val) {
            this.pager.take = val;
            this.pager.current = 1;
            this.getJdtList(true);
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.getJdtList(true);
        },
        handlePagerJump(pagerJump) {
            // if(pagerJump > Math.ceil(this.pager.total / this.pager.size)) {
            //     return;
            // } else {
            //     this.handleCurrentChange(pagerJump);
            // }
            pagerJump = Number.parseInt(pagerJump);
            if (
                pagerJump > 0 &&
                pagerJump <= Math.ceil(this.pager.total / this.pager.take)
            ) {
                this.handleCurrentChange(pagerJump);
            }
        },
        computedDescription(item) {
            let str = '';
            // type=1 JDT推荐注册
            // type=2 顾问推荐职位
            switch(item.type) {
                case 1: 
                    str = `连接了候选人 <a class="green">${(item.extensionProperties && item.extensionProperties.NickName) || ''}</a>`;
                    break;
                case 2: 
                    // str = `为候选人 <a class="green">${item.extensionProperties.JDTCandidateName}</a> 推荐了 <a class="green" href="/Headhunting/MyCompany.html#/Job/${item.extensionProperties.JobId}" target="_blank" title="${item.extensionProperties.JobName}">${item.extensionProperties.JobName}</a> 职位`;
                    str = `为候选人 <a class="green">${(item.extensionProperties && item.extensionProperties.JDTCandidateName) || ''}</a> 推荐了职位`;
                    break;
                default: 
            }
            return str;
        },
		handleShowMore() {
            this.getStreamData();
        },

        handleAddActivity() {
            this.$refs.addActivity.show();
        },
        handleJdtReport() {
            // window.open("/Headhunting/MyCompany.html#/jdtReport");
            window.open("/#/jdtReport");
        },
        showJdtDialog() {
            this.jdtDialogVisible = true;
        },
        hideJdtDialog() {
            this.jdtDialogVisible = false;
        },
        handleActivityOperate(id, command) {
            switch(command) {
                case 'removeActivity': 
                    this.$confirm(`是否确定删除当前活动？`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.removeActivity(id);
                    }).catch(() => {
                        shortTips(`取消操作！`)
                    });
                    break;
                default:
                    break;
            }
        },
        removeActivity(id) {
            _request({
                method: 'POST',
                url: jdtUrl.jdt_activity_delete.replace(/%p/, id),
                baseURL: 'LbdJavaApi',
                javaProject: 'jdt',
            }).then(res =>{
                shortTips(`删除活动成功！`);
                this.getJdtList();
            }).finally(() => {
                // this.activityListLoading = false;
            });
        },
        viewActivity(activityId) {
            if(activityId) {
                this.$refs.viewActivity.show(activityId);
            } else{
                shortTips(`无效活动，无法查看详情！`);
            }
        },
        addActivityCb() {
            this.pager.current = 1;
            this.getJdtList();
        },
        // 最新动态数据
        getStreamData() {
            const params = {
                currentPage: this.trendStart,
                pageSize: 5,
            };
            this.tendLoading = true;
            _request({
                method: 'POST',
                url: jdtUrl.jdt_activity_stream,
                baseURL: 'LbdJavaApi',
                javaProject: 'jdt',
                data: params,
            }).then(res =>{
                if(this.trendStart == 1) {
                    console.log(typeof res.data)
                    this.trendList = res.data || [];
                } else {
                    this.trendList = this.trendList.concat(res.data);
                }
                this.trendTotal = res.count;
                this.trendStart++;
            }).finally(() => {
                this.tendLoading = false;
            });
        },
	}
}
</script>

<style lang="scss" scope>
@media screen and (max-width: 1400px) {
    .ztc-l {
        width: calc(100% - 340px) !important;
    }
    .ztc-r {
        width: 320px !important;
    }
    .banner {
        width: calc(100% - 450px) !important;
    }
    .right-entrys {
        width: 430px !important;
    }
}
@media screen and (min-width: 1400px) and (max-width: 1680px) {
    .ztc-l {
        width: calc(100% - 400px) !important;
    }
    .ztc-r {
        width: 380px !important;
    }
    .banner {
        width: calc(100% - 450px) !important;
    }
    .right-entrys {
        width: 430px !important;
    }
}
@media screen and (min-width: 1680px) and (max-width: 1800px) {
    .ztc-l, .banner {
        width: calc(100% - 450px) !important;
    }
    .ztc-r, .right-entrys {
        width: 430px !important;
    }
}

.ell{
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}
.hidden{
    visibility: hidden;
}
.green{
    color: #38BC9D;
}
.ztc-container{
    padding: 20px;
    height: 100%;
    overflow-y: auto;

    .ztc-top {
        margin-bottom: 20px;
        width: 100%;
        background-color: #fff;
        border-radius: 8px;
        padding: 20px 0;
        display: flex;
        justify-content: space-between;

        .banner{
            padding: 0 20px;
            width: calc(100% - 490px);
            height: 208px;
            >img{
                width: 100%;
                height: 208px;
                cursor: pointer;
                object-fit: cover;
                border-radius: 8px;
            }
        }

        .right-entrys{
            width: 470px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .top-btn{
                padding: 0 10px;
                margin-right: 20px;
                height: 40px;
                line-height: 40px;
                box-sizing: border-box;
                background-color: #fff;
                border: 1px solid #ddd;
                cursor: pointer;
                text-align: center;
                font-size: 14px;
                color: #666;  
                border-radius: 4px;
                &:hover{
                    box-shadow: 2px 2px 3px 0 #ddd;
                }
            }
            .top-add-activity{
                width: 110px;
                background-color: #38BC9D;
                border: none;
                color: #FFFFFF;
                &:hover{
                    box-shadow: 2px 2px 3px 0 #ddd;
                }
            }
        }
    }

    .ztc-bottom {
        display: flex;
        justify-content: space-between;
        .ztc-l{
            min-height: calc(100vh - 350px);
            background-color: #fff;
            border-radius: 8px;
            display: inline-block;
            width: calc(100% - 490px);
            font-size: 12px;
            .list-wrap{
                width: 100%;
                box-sizing: border-box;
                margin-top: 16px;
                min-height: 80px;
                .list-item{
                    box-sizing: border-box;
                    border-bottom: 1px solid #eee;
                    padding: 30px 20px;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    &:hover{
                        background: #F6F6F6;
                    }
                    .list-l{
                        flex: 1;
                        max-width: 480px;
                        font-size: 16px;
                        color: #333333;
                        line-height: 36px;
                        cursor: pointer;
                    }
                    .list-m{
                        width: 270px;
                        font-size: 14px;
                        color: #999999;
                        line-height: 36px;
                    }
                    .list-r{
                        width: 80px;

                        .el-dropdown {
                            height: 30px;
                            margin-top: 10px;
                            .el-dropdown-link {
                                color: #ccc;
                                display: inline-block;
                                width: 60px;
                                text-align: center;
                                line-height: 20px;
                                font-size: 26px;
                            }
                        }

                        .job-more-opr-dropdown.el-dropdown-menu {
                            margin-top: 0;
                            padding: 0;
                            .el-dropdown-menu__item {
                                text-align: center;
                            }
                        }
                    }
                }
            }

            .empty {
                width: 100%;
                min-height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .empty-img {
                    display: inline-block;
                    background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                    background-size: contain;
                    width: 170px;
                    height: 160px;
                }
                .empty-data {
                    font-size: 14px;
                    margin-bottom: 0px;
                }
            }
            .pagination-wrap{
                width: 100%;
                height: auto;
                overflow: hidden;
            }
            .job-list-pagination.el-pagination {
                margin: 30px 20px;
                padding: 0;
                font-weight: normal;
                box-sizing: border-box;
                .btn-next, .btn-prev {
                    height: 38px;
                    border: 1px solid;
                }
                .btn-prev {
                    border-right: none;
                    border-radius: 4px 0 0 4px;
                }
                .btn-next {
                    border-left: none;
                    border-radius: 0 4px 4px 0;
                }
                .el-pagination__sizes .el-input{
                    width: 87px;
                    .el-input__inner {
                        color: #666;
                        .el-select__caret{
                            color: #999;
                        }
                    }
                }
                .el-pager {
                    border-top: 1px solid;
                    border-bottom: 1px solid;
                    li {
                        min-width: 36px;
                        height: 36px;
                        line-height: 36px;
                        font-size: 14px;
                    }
                }
                .btn-next, .btn-prev, .el-pager {
                    border-color: #DDDDDD;
                }
                .el-input__inner, .el-pagination__total, .el-pagination__jump {
                    height: 38px;
                    line-height: 38px;
                    font-size: 14px;
                }
                .pagination-text{
                    color: #999;
                    span{
                        height: 38px;
                        line-height: 38px;
                        font-size: 14px;
                        .el-input{
                            width: 48px;
                            margin: 0 5px;
                            &__inner{
                                height: 38px;
                                line-height: 38px;
                                padding: 0 5px;
                            }
                        }
                        &:nth-of-type(2){
                            margin-left: 5px;
                            color: $primary;
                            cursor: pointer;
                        }
                    }
                }
                .el-pagination__total,
                .el-pagination__sizes,
                .pagination-text {
                    float: right;
                }
            }
        }
        h2{
            font-size: 16px;
            font-family: 'Microsoft YaHei';
            font-weight: bold;
            color: #333;
            &.ztc-l-list-title {
                height: 60px;
                line-height: 60px;
                border-bottom: 1px solid #eee;
            }
            &::before{
                content: '';
                display: inline-block;
                width: 8px;
                height: 20px;
                background: #38BC9D;
                margin-right: 12px;
                vertical-align: text-top;
            }
        }
        .ztc-r{
            display: inline-block;
            width: 470px;
            margin-left: 20px;
            font-size: 14px;
            vertical-align: top;

            .job-order-list-wrapper {
                padding: 20px;
                background-color: #fff;
                border-radius: 8px;
                .job-order-list-title {
                    margin-bottom: 15px;
                    font-size: 18px;
                    color: #333;
                    // .job-order-list-icon {
                    //     margin-right: 6px;
                    //     font-size: 16px;
                    //     color: $primary;
                    //     vertical-align: top;
                    //     line-height: inherit;
                    // }
                    &::before{
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 20px;
                        background: #38BC9D;
                        margin-right: 12px;
                        vertical-align: bottom;
                    }
                }
                .job-order-list {
                    border: 1px solid #ccc;
                    border-radius: 2px;
                    padding: 10px 20px;
                    .job-order-item {
                        height: 45px;
                        line-height: 45px;
                        color: #666;
                        & + .job-order-item {
                            border-top: 1px solid #eee;
                            line-height: 44px;
                        }
                        .job-order-item-icon {
                            margin-right: 10px;
                            font-size: 16px;
                            color: #ccc;
                            vertical-align: top;
                            line-height: inherit;
                        }
                        .job-order-item-name {
                            display: inline-block;
                            max-width: 154px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            color: #666;
                            cursor: default;
                            &:hover {
                                // color: $primary;
                            }
                            &.job-location-name {
                                cursor: default;
                                color: #666;
                            }
                            &.disable-link {
                                cursor: default;
                                color: #666;
                            }
                        }
                        .job-order-item-count {
                            display: inline-block;
                            float: right;
                            text-align: center;
                            width: 42px;
                            // cursor: pointer;
                            &:hover {
                                // color: $primary;
                            }
                            &.job-location-count {
                                cursor: default;
                                color: #666;
                            }
                        }
                    }
                    .job-order-list-null {
                        height: 45px;
                        line-height: 44px;
                        color: #666;
                    }
                }
                .job-order-more-btn {
                    width: 100%;
                    height: 44px;
                    margin-top: 10px;
                    line-height: 44px;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 2px;
                    border: 1px solid #ccc;
                    font-size: 14px;
                    color: #b9b9b9;
                    &:hover {
                        color: $primary;
                    }
                }
                & + .job-order-list-wrapper{
                    margin-top: 40px;
                }
            }


            .ztc-news{
                padding: 20px;
                background-color: #fff;
                border-radius: 8px;
                width: 100%;
                margin-top: 20px;
                .title{
                    padding-bottom: 10px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #333333;
                    // line-height: 36px;
                    &::before{
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 20px;
                        background: #38BC9D;
                        margin-right: 10px;
                        vertical-align: bottom;
                    }
                }
                .news-container{
                    background: #fff;
                    padding-bottom: 20px;
                    ul{
                        margin-bottom: 0px;
                    }
                }
                .news-item {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-flow: row nowrap;
                    box-sizing: border-box;
                    margin: 12px 0;
                    .pic{
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 11px;
                    }
                    .new-content{
                        flex: 1;
                        max-width: 237px;
                        >p{
                            font-size: 14px;
                            font-weight: 400;
                            color: #666666;
                            line-height: 17px;

                            .gray{
                                color: #B9B9B9;
                            }
                            .green{
                                color: #38BC9D;
                            }
                            .name{
                                display: inline-block;
                                color: #B9B9B9;
                                max-width: 140px;
                                .real-name{
                                    color: black;
                                }
                            }
                            .time{
                                color: #B9B9B9;
                                padding-left: 18px;
                                font-size: 14px;
                                // display: inline-block;
                                float: right;
                            }
                        }
                    }
                }
                .empty{
                    text-align: center;
                    padding: 30px 0 10px;
                }
                .news-bottom{
                    height: 28px;
                    background: #FFFFFF;
                    border: 1px solid #CCCCCC;
                    box-sizing: border-box;
                    text-align: center;
                    line-height: 26px;
                    cursor: pointer;
                    .news-show-more{
                        color: #ccc;
                        font-size: 12px;
                    }
                }

            }
        }
    }
}
</style>