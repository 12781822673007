var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ztc-container" },
    [
      _c("div", { staticClass: "ztc-top" }, [
        _c("div", { staticClass: "banner" }, [
          _c("img", {
            attrs: {
              src: _vm.bannerInfo.bannerImageUrl || _vm.defaultImg,
              onerror: _vm.defaultImg,
              alt: "职通车banner",
            },
            on: {
              click: function ($event) {
                return _vm.viewActivity(_vm.bannerInfo.id)
              },
            },
          }),
        ]),
        _c("div", { staticClass: "right-entrys" }, [
          _vm.userInfo.isAdministrator
            ? _c(
                "div",
                {
                  staticClass: "top-btn top-add-activity",
                  on: { click: _vm.handleAddActivity },
                },
                [_vm._v("新增活动")]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "top-btn", on: { click: _vm.handleJdtReport } },
            [_vm._v("Csdn职通车数据")]
          ),
          _c(
            "div",
            { staticClass: "top-btn", on: { click: _vm.showJdtDialog } },
            [_vm._v("获取职通车二维码")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "ztc-bottom" }, [
        _c("div", { staticClass: "ztc-l" }, [
          _c("h2", { staticClass: "ztc-l-list-title" }, [
            _vm._v("Csdn职通车活动"),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.activityListLoading,
                  expression: "activityListLoading",
                },
              ],
              staticClass: "list-wrap",
            },
            [
              _vm.activityList.length
                ? _vm._l(_vm.activityList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: "activityList_" + index,
                        staticClass: "list-item",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "list-l ell",
                            domProps: { textContent: _vm._s(item.title) },
                            on: {
                              click: function ($event) {
                                return _vm.viewActivity(item.id)
                              },
                            },
                          },
                          [_vm._v("标题内容兴致冲冲的擦好发霉的镜头，相机充")]
                        ),
                        _c("div", { staticClass: "list-m" }, [
                          _vm._v(
                            _vm._s(item.startDate) + "/" + _vm._s(item.endDate)
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "list-r",
                            class: { hidden: !_vm.userInfo.isAdministrator },
                          },
                          [
                            _c(
                              "el-dropdown",
                              {
                                attrs: { placement: "bottom" },
                                on: {
                                  command: function ($event) {
                                    return _vm.handleActivityOperate(
                                      item.id,
                                      ...arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c("span", {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.moreOperateLoading,
                                      expression: "moreOperateLoading",
                                    },
                                  ],
                                  staticClass: "el-dropdown-link el-icon-more",
                                }),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    staticClass: "job-more-opr-dropdown",
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c(
                                      "el-dropdown-item",
                                      { attrs: { command: "removeActivity" } },
                                      [_vm._v("删除活动")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
          _vm.activityList.length == 0
            ? _c("div", { staticClass: "empty" }, [
                _c("span", { staticClass: "empty-img" }),
                _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "pagination-wrap" },
            [
              _c(
                "el-pagination",
                {
                  staticClass: "job-list-pagination",
                  attrs: {
                    "current-page": _vm.pager.current,
                    "page-sizes": [10, 30, 50],
                    "page-size": _vm.pager.take,
                    layout: "prev, pager, next, slot, total, sizes",
                    total: _vm.pager.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                },
                [
                  _c("span", { staticClass: "pagination-text" }, [
                    _c(
                      "span",
                      [
                        _vm._v(
                          "\n                          前往\n                          "
                        ),
                        _c("el-input", {
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handlePagerJump(_vm.pagerJump)
                            },
                          },
                          model: {
                            value: _vm.pagerJump,
                            callback: function ($$v) {
                              _vm.pagerJump = $$v
                            },
                            expression: "pagerJump",
                          },
                        }),
                        _vm._v(
                          "\n                          页\n                      "
                        ),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handlePagerJump(_vm.pagerJump)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                          跳转\n                      "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "ztc-r" }, [
          _c("div", { staticClass: "job-order-list-wrapper" }, [
            _c("div", { staticClass: "job-order-list-title" }, [
              _vm._v(
                "\n                      连接候选人数排行榜\n                  "
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.memberLoading,
                    expression: "memberLoading",
                  },
                ],
                staticClass: "job-order-list",
              },
              [
                _vm.memberOrders.length
                  ? _vm._l(_vm.memberOrders, function (member, memberIndex) {
                      return _c(
                        "div",
                        { key: memberIndex, staticClass: "job-order-item" },
                        [
                          _c("i", {
                            staticClass: "job-order-item-icon el-icon-s-custom",
                          }),
                          _c(
                            "a",
                            {
                              staticClass: "job-order-item-name",
                              attrs: { href: "javascript:;" },
                            },
                            [
                              _vm._v(
                                "\n                                  " +
                                  _vm._s(member.realName) +
                                  "\n                              "
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "job-order-item-count" }, [
                            _vm._v(
                              "\n                                  " +
                                _vm._s(member.total) +
                                "\n                              "
                            ),
                          ]),
                        ]
                      )
                    })
                  : _c("span", { staticClass: "job-order-list-null" }, [
                      _vm._v("暂无数据"),
                    ]),
              ],
              2
            ),
          ]),
          _c("div", { staticClass: "ztc-news" }, [
            _c("h2", { staticClass: "title" }, [_vm._v("Csdn职通车最新动态")]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.tendLoading,
                    expression: "tendLoading",
                  },
                ],
                staticClass: "news-container",
              },
              [
                _c(
                  "ul",
                  [
                    _vm._l(_vm.trendList, function (item, index) {
                      return _c(
                        "li",
                        { key: "trend_" + index, staticClass: "news-item" },
                        [
                          _c("avatar", {
                            staticClass: "pic",
                            attrs: {
                              size: "md",
                              src: _vm.formatAvatarUrl(
                                `/Data/Avatar/${item.avatarId}`
                              ),
                              userId: item.posterId,
                              enableCard: true,
                              enableLink: true,
                            },
                          }),
                          _c("div", { staticClass: "new-content" }, [
                            _c("p", [
                              _c(
                                "span",
                                {
                                  staticClass: "name ell",
                                  attrs: {
                                    title:
                                      item.posterRealName +
                                      "@" +
                                      item.posterNickName,
                                  },
                                },
                                [
                                  _c("span", { staticClass: "real-name" }, [
                                    _vm._v(_vm._s(item.posterRealName)),
                                  ]),
                                  _vm._v(
                                    "\n                                          @" +
                                      _vm._s(item.posterNickName) +
                                      "\n                                      "
                                  ),
                                ]
                              ),
                              _c("span", { staticClass: "time" }, [
                                _vm._v(_vm._s(item.created)),
                              ]),
                            ]),
                            _c("p", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.computedDescription(item)
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      )
                    }),
                    _vm.trendList.length == 0
                      ? _c("div", { staticClass: "empty" }, [
                          _vm._v("暂无数据"),
                        ])
                      : _vm._e(),
                    _vm.showMore
                      ? _c(
                          "div",
                          {
                            staticClass: "news-bottom",
                            on: { click: _vm.handleShowMore },
                          },
                          [_vm._m(0)]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _c("add-activity", {
        ref: "addActivity",
        on: { "add-activity-cb": _vm.addActivityCb },
      }),
      _vm.userInfo.isCFUser && _vm.jdtDialogVisible
        ? _c("image-dialog", {
            attrs: {
              width: "500px",
              height: "880px",
              imageSrc: _vm.jdtQcodeImage,
              imageAlt: "Csdn职通车二维码",
            },
            on: { hideDialog: _vm.hideJdtDialog },
          })
        : _vm._e(),
      _c("view-activity", {
        ref: "viewActivity",
        attrs: { width: "880px", height: "700px" },
        on: { hideDialog: _vm.hideJdtDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "news-show-more" }, [
      _vm._v("加载更多"),
      _c("i", { staticClass: "el-icon-d-arrow-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }