<template>
    <el-dialog
        :width="width"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        custom-class="image-dialog">
        <div class="post-container" :style="{'width': width, 'height': height}">
            <div class="content" v-loading="viewLoading">
                <div class="content-inner">
                    <div class="pic">
                        <!-- src="https://bazapictest.oss-cn-heyuan.aliyuncs.com/222.jpg" -->
                        <img 
                            :src="content.bannerImageUrl"
                            :onerror="defaultImg"
                            :alt="content.title">
                    </div>
                    <div class="title">
                        {{content.title}}
                    </div>
                    <div class="time">
                        {{content.startDate}}/{{content.endDate}}
                    </div>
                    <pre v-text="content.descrption"></pre>
                </div>
            </div>

            <span class="close-post-btn" @click="hideDialog"></span>
        </div>
    </el-dialog>
</template>

<script>
import { jdt as jdtUrl } from '#/js/config/javaApi.json';
export default {
    name: 'view-activity',
	components: {
	},
	props: {
        width: {
            type: String,
            default: '760px'
        },
        height: {
            type: String,
            default: '520px'
        },
    },
    data() {
        return {
            dialogVisible: false,
            viewLoading: false,
            content: {},
            defaultImg: 'this.src="' + require('@src/assets/images/ztc/ztc-banner.jpg') + '"',
        }
    },
    created() {

    },
	mounted() {
		
	},
	methods: {
        hideDialog() {
            document.querySelector('.content-inner').scrollTop = 0;
            this.dialogVisible = false; 
        },
        show(activityId) {
            this.dialogVisible = true;
            this.getActivityDetail(activityId);
        },
        getActivityDetail(activityId) {
            if(!activityId) return;
            this.viewLoading = true;
            _request({
                method: 'GET',
                url: jdtUrl.jdt_activity_detail.replace(/%p/, activityId),
                baseURL: 'LbdJavaApi',
                javaProject: 'jdt',
            }).then(res =>{
                this.content = res || {};
            }).finally(() => {
                this.viewLoading = false;
            });
        }
    }
}
</script>
<style lang="scss" scope>
    .image-dialog.el-dialog {
        transform: unset !important;
        position: relative !important;
        top: unset !important;
        left: unset !important;
        margin-top: 10vh !important;
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            padding: 0;
        }
        .post-container {
            width: 760px;
            height: 520px;
            box-sizing: border-box;
            padding: 20px;
            position: relative;
            .content{
                // overflow-y: auto;
                height: 100%;
                .content-inner{
                    height: 100%;
                    overflow-y: auto;
                    font-family: 'Microsoft Yahei';
                    .pic{
                        width: 100%;
                        height: auto;
                        max-height: 208px;
                        >img{
                            width: 100%;
                            height: auto;
                            max-height: 208px;
                        }
                    }
                    .title{
                        font-size: 30px;
                        color: #333333;
                        line-height: 33px;
                        margin-top: 40px;
                    }
                    .time{
                        font-size: 14px;
                        color: #999999;
                        line-height: 14px;
                        margin-bottom: 40px;
                        margin-top: 20px;
                    }
                    >pre{
                        border-top: 1px solid #ccc;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 38px 0;
                        font-family: 'Microsoft Yahei';
                        word-wrap:break-word;
                        white-space: pre-wrap;
                    }
                }
            }
            .close-post-btn {
                width: 18px;
                height: 18px;
                color: #fff;
                border-radius: 9px;
                border: 1px solid #fff;
                position: absolute;
                right: -24px;
                top: -24px;
                line-height: 18px;
                cursor: pointer;
                &::before {
                    content: "×";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                &:hover {
                    color: #fff;
                    background-color: #e95352;
                    border-color: #e95352;
                }
            }
        }
    }
</style>